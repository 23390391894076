import '../less/main.less';
// import $ from 'jquery';
import IScroll from 'fullpage.js/vendors/scrolloverflow';
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';
import {
    type
} from 'os';
// var modal = require('./ui.modal');

$(function () {
    // 如果是主页

    if ($("#fullpage").length > 0) {
        // fullpage设置
        var fullPageInstance = new fullpage('#fullpage', {
            licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
            dragAndMove: "true",
            anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6'],
            menu: '#myMenu',
            scrollOverflow: true,
            slidesNavigation: true,
            controlArrows: false,
            scrollHorizontally: true
        });

        // 同步页面的高
        var h = $(".page1").height();
        $(".full-container.one,.full-container.two,.full-container.three").height(h);

        // herder.home
        $("header").addClass("home");

        // 左侧导航出现
        $("#myMenu").on("mouseenter", function () {
            // 防再次触发

            if (!$(".bg-myMenu").hasClass("show") && !$(".black-container").hasClass("show")) {
                $(".bg-myMenu").addClass("show");
                $(".shadow.left").fadeIn();
                // 禁用滚动
                fullpage_api.setAllowScrolling(false);
                var index = 0;
                var $span = $(this).find("span");
                var len = $span.length;
                var timer = setInterval(function () {
                    if (len > 0 && $(".bg-myMenu").hasClass("show")) {
                        $span.eq(index).fadeIn().addClass("moved");
                        if (index <= len) {
                            index++;
                        } else {
                            clearInterval(timer);
                            // console.log("done");
                        }
                    } else {
                        clearInterval(timer);
                        $("#myMenu span").hide().removeClass("moved");
                    }
                }, 120)
            }
        })

        // 左侧导航消失
        $(".shadow.left").on("mouseenter", function () {
            $(".bg-myMenu").removeClass("show");
            $("#myMenu span").hide().removeClass("moved");
            fullpage_api.setAllowScrolling(true);
            $(".shadow.left").fadeOut();
        })
    }

    // 根据页面设置body的padding
    (function initBodyPT() {
        var h_header = $("header").outerHeight();
        if (!$("#fullpage").length > 0) {
            $("body").css("padding-top", h_header);
        }
    })();

    // right-nav显示
    $(".right-nav").on("click", function () {
        var $shadow_r = $(".shadow.right");
        if ($shadow_r.css("display") == "none") {
            $("body").addClass("lock");
            $shadow_r.fadeIn()
            $(".black-container").addClass("show");
            try {
                // 禁用滚动
                fullpage_api.setAllowScrolling(false);
            } catch (error) {}

        } else {
            $("body").removeClass("lock");
            $shadow_r.fadeOut();
            $(".black-container").removeClass("show");
            try {
                // 启用滚动
                fullpage_api.setAllowScrolling(true);
            } catch (error) {}

        }
    })

    // right-nav 关闭按钮
    $(".shadow.right .close").on("click", function () {
        $("body").removeClass("lock");
        $(".shadow.right").fadeOut();
        $(".black-container").removeClass("show");
        try {
            fullpage_api.setAllowScrolling(true);
        } catch (error) {}

    })

    // 右侧shadow点击空白处关闭
    $(".shadow.right").on("click", function (e) {
        if (e.target.className === "shadow right") {
            $("body").removeClass("lock");
            $(".black-container").removeClass("show");
            $(this).fadeOut();
            try {
                fullpage_api.setAllowScrolling(true);
            } catch (error) {}

        };
    })


    // 视频模态框，切换
    // $(".video-list li").on("click", function () {
    //     var src = $(this).attr("data-src");
    //     $("#modal-video").find("video").attr("src", src);
    //     $("#modal-video").modal("open");
    // })

    // 关闭视频
    // $("#modal-video").on("close.modal.amui", function () {
    //     $(this).find("video").attr("src", "");
    // })

    // 下载页面锚点链接
    $(".btn.download").on("click", function (e) {
        e.preventDefault();
        $("body,html").animate({
            "scrollTop": $(".file-container").offset().top
        });
    })

    // 下载页面激活的图标重新排列
    $(".ingredients-container li:not(.active)").appendTo($(".ingredients-container>ul"));


    // sweetin部件显隐
    $(".circle.img3-1").hover(function () {
        $(".img3-2-container,.img3-3-container,.img3-4-container").addClass("fadeout");
        $(this).children("P").addClass("show");
    }, function () {
        $(".img3-2-container,.img3-3-container,.img3-4-container").removeClass("fadeout");
        $(this).children("P").removeClass("show");
    })

    $(".circle.img3-2").hover(function () {
        $(".img3-1-container,.img3-3-container,.img3-4-container").addClass("fadeout");
        $(this).children("P").addClass("show");
    }, function () {
        $(".img3-1-container,.img3-3-container,.img3-4-container").removeClass("fadeout");
        $(this).children("P").removeClass("show");
    })

    $(".circle.img3-3").hover(function () {
        $(".img3-2-container,.img3-1-container,.img3-4-container").addClass("fadeout");
        $(this).children("P").addClass("show");
    }, function () {
        $(".img3-2-container,.img3-1-container,.img3-4-container").removeClass("fadeout");
        $(this).children("P").removeClass("show");
    })

    $(".circle.img3-4").hover(function () {
        $(".img3-2-container,.img3-3-container,.img3-1-container").addClass("fadeout");
        $(this).children("P").addClass("show");
    }, function () {
        $(".img3-2-container,.img3-3-container,.img3-1-container").removeClass("fadeout");
        $(this).children("P").removeClass("show");
    })

})

$(document).ready(function () {
    var i = 0,
        b = 0,
        s = 0;
    var datas = []; //二维数组
    var arr_index = -1;
    var htmls = '';
    var flag = true;

    $('#cart').on('click', function () {
        $('.shop_cart').show();
    })
    $('.bank').on('click', function () {
        $('.shop_cart').hide();
    })

    //判断选择产品
    $('.black').on('click', function () {
        $(this).addClass('con').siblings().removeClass('con');
        b = 1;
        $('#color').val('Black');
        if (b == 1 && s == 0) {
            i = 2
            $('#pid').val(3);
            $('#price').text(1299);

        } else if (b == 1 && s == 1) {
            i = 3
            $('#pid').val(4);
            $('#price').text(1799);
        }
        $('.item').eq(i).fadeIn().siblings().fadeOut();
        // $('.tab').eq(i).addClass('active').siblings().removeClass('active');

    })
    $('.white').on('click', function () {
        $(this).addClass('con').siblings().removeClass('con');
        b = 0;
        $('#color').val('White');
        if (b == 0 && s == 0) {
            i = 0
            $('#pid').val(1);
            $('#price').text(1299);
        } else if (b == 0 && s == 1) {
            i = 1
            $('#pid').val(2);
            $('#price').text(1799);
        }

        $('.item').eq(i).fadeIn().siblings().fadeOut();
        // $('.tab').eq(i).addClass('active').siblings().removeClass('active');

    })
    $('.std').on('click', function () {
        $(this).addClass('con').siblings().removeClass('con');
        s = 0;
        $('#model').val('Std');
        if (b == 0 && s == 0) {
            i = 0
            $('#pid').val(1);
            $('#price').text(1299);
        } else if (b == 1 && s == 0) {
            i = 2
            $('#pid').val(3);
            $('#price').text(1299);
        }
        $('.item').eq(i).fadeIn().siblings().fadeOut();
        // $('.tab').eq(i).addClass('active').siblings().removeClass('active');
    })
    $('.pro').on('click', function () {
        $(this).addClass('con').siblings().removeClass('con');
        s = 1;
        $('#model').val('Pro');
        if (b == 0 && s == 1) {
            i = 1
            $('#pid').val(2);
            $('#price').text(1799);
        } else if (b == 1 && s == 1) {
            i = 3
            $('#pid').val(4);
            $('#price').text(1799);
        }
        $('.item').eq(i).fadeIn().siblings().fadeOut();
        // $('.tab').eq(i).addClass('active').siblings().removeClass('active');

    })



    //ink选中样式
    function tab(a, i, id, str) {
        $(a).on('click', function () {
            // console.log(i);
            $(this).addClass('con').siblings().removeClass('con');
            $('#color').val($(this).text());
            $('.item').eq(id).fadeIn().siblings().fadeOut();
            $('#pid').val(i);
            $('#annotate').val(str);
        })
    }
    tab('.Tricolor', 5, 3, 'Coffee x1, Matcha x1, Golden yellow x1');
    tab('.coffee', 6, 5, 'Coffee x3');
    tab('.matcha', 7, 6, 'Matcha x3');
    tab('.golden', 8, 7, 'Golden yellow x3');

    //提交数据
    $('.Submit').on('click', function () {
        var data1 = [{
            color: $('#color').val(),
            model: $('#model').val(),
            nummber: Number($('#nummber').val()),
            pid: Number($('#pid').val())
        }];
        data1 = JSON.stringify(data1);
        $("input[name=arr]").val(data1);
        subForm('#myForm');
    })
    $('.Submit1').on('click', function () {

        console.log(datas);
        var data2 = JSON.stringify(datas);
        console.log(data2);

        $("input[name=arr]").val(data2);
        subForm('#myForm');
    })
    function subForm(form) {
        $(form).submit();
    }

    //加入购物车
    var str_html = '';
    datas = sessionStorage.getItem("coffee");
    if (datas == null) {
        datas = [];
    } else {
        datas = JSON.parse(datas);
    }
    console.log(datas);
    load();
    $('.basket').on('click', function (e) {
        var ao = true,
            p = 0;
        //显示购物车
        // $('.shop_cart').show();
        //收集数据
        var data = {
            color: $('#color').val(),
            model: $('#model').val(),
            nummber: Number($('#nummber').val()),
            pid: Number($('#pid').val()),
            price: Number($('#price').text())
        }
        //有重复
        if (datas.length > 0) {
            for (let i = 0; i < datas.length; i++) {
                var sub = 0;
                //判断产品是否存在重复
                if (data.pid == datas[i].pid) {
                    ao = false;
                    sub = Number(data.nummber) + Number(datas[i].nummber);
                    datas[i].nummber = sub;
                    $('.car_pro').eq(i).find('input[name=nummber]').val(sub);
                    $('.car_pro').eq(i).find('.price').text(datas[i].price * datas[i].nummber);
                    break;
                }
            }
        }
        if (ao) {
            datas.push(data);
            console.log(data.pid);

            htmls = '<div class="car_pro"><div class="img"><img src="/imgs/coffee/' + data.pid + '.jpg" alt=""><input type="hidden" name="pid" value="' + data.pid + '" id="pid_j"></div><div class="said"><h4>Desserts Decoration Maker</h4><span class="anno">' + data.color + ' ' + data.model + '</span></div><div class="num"><span class="num_red" >-</span><input type="text" name="nummber" value="' + data.nummber + '" id="nummbers"><span class="num_add" >+</span></div><div class="prices"><span>$</span><div class="price">' + data.price * data.nummber + '</div></div><div class="del"><img src="/imgs/coffee/del.png" alt=""></div></div>'
            $('#carpro').append(htmls);
        }
        console.log(datas);
        $("#msg").show().animate({
            width: '300px'
        }, 300).fadeOut(2000); //提示信息 
        sessionStorage.setItem("coffee", JSON.stringify(datas));
        //统计价格
        for (let i = 0; i < datas.length; i++) {
            p = p + datas[i].price * datas[i].nummber;
        }
        $('.sub_prices').text(p)

        //录入数字
        // $('#nummbers').change(function () {
        //     if (Number($(this).parent().parent().find('.price').text()) % 1299 == 0) {
        //         $(this).parent().parent().find('.price').text($(this).val() * 1299);
        //     } else {
        //         $(this).parent().parent().find('.price').text($(this).val() * 1799);
        //     }
        // })
        // $('#nummbers').blur(function () {
        //     if (Number($(this).parent().parent().find('.price').text()) % 1299 == 0) {
        //         $(this).parent().parent().find('.price').text($(this).val() * 1299);
        //     } else {
        //         $(this).parent().parent().find('.price').text($(this).val() * 1799);
        //     }
        // })
    })
    //ink加入购物车
    $('.ink_basket').on('click', function () {
        var ao = true,
            p = 0;
        //显示购物车
        // $('.shop_cart').show();
        //收集数据
        var data = {
            nummber: Number($('#nummber').val()),
            color: $('#color').val(),
            pid: Number($('#pid').val()),
            price: Number($('#price').text()),
            annotate: $('#annotate').val()
        }
        if (datas.length > 0) {
            for (let i = 0; i < datas.length; i++) {
                var sub = 0;
                //判断产品是否存在重复
                if (data.pid == datas[i].pid) {
                    ao = false;
                    sub = Number(data.nummber) + Number(datas[i].nummber);
                    datas[i].nummber = sub;
                    $('.car_pro').eq(i).find('input[name=nummber]').val(sub);
                    $('.car_pro').eq(i).find('.price').text(datas[i].price * datas[i].nummber);
                    break;
                }
            }
        }
        if (ao) {
            datas.push(data);
            htmls = '<div class="car_pro"><div class="img"><img src="/imgs/ink/' + data.pid + '.jpg" alt=""><input type="hidden" name="pid" value="' + data.pid + '" id="pid_j"></div><div class="said"><h4>Desserts Decoration Maker</h4><span class="anno">' + data.annotate + '</span></div><div class="num"><span class="num_red" >-</span><input type="text" name="nummber" value="' + data.nummber + '" id="nummbers"><span class="num_add" >+</span></div><div class="prices"><span>$</span><div class="price">' + data.price * data.nummber + '</div></div><div class="del"><img src="/imgs/coffee/del.png" alt=""></div></div>'
            $('#carpro').append(htmls);
        }
        console.log(datas);
        //统计价格
        for (let i = 0; i < datas.length; i++) {
            p = p + datas[i].price * datas[i].nummber;
        }
        $('.sub_prices').text(p);
        $("#msg").show().animate({
            width: '300px'
        }, 300).fadeOut(2000); //提示信息 
        sessionStorage.setItem("coffee", JSON.stringify(datas));
    })

    $(document).on("click", ".num_add", function () {
        var p = 0,
            str = '';
        str = $(this).parent().parent().find("#pid_j").val();
        console.log(str);
        var outerContainter = $(this).parents(".car_pro");
        var num = parseInt(outerContainter.find("input[name=nummber]").val());
        num++;
        outerContainter.find("input[name=nummber]").val(num);
        var pri = outerContainter.find(".price").text();
        if (pri % 1299 == 0) {
            outerContainter.find(".price").text(1299 * num)
        } else if (pri % 1799 == 0) {
            outerContainter.find(".price").text(1799 * num)
        } else {
            outerContainter.find(".price").text(199 * num)
        }
        console.log(datas);
        for (let i = 0; i < datas.length; i++) {
            if (datas[i].pid == str) {
                datas[i].nummber += 1;
            }
            p += datas[i].price * datas[i].nummber;
            console.log(p);

        }
        $('.sub_prices').text(p)
        sessionStorage.setItem("coffee", JSON.stringify(datas));
    });
    $(document).on("click", ".num_red", function () {
        var p = 0,
            str = '';
        str = $(this).parent().parent().find("#pid_j").val();
        var outerContainter = $(this).parents(".car_pro");
        var num = parseInt(outerContainter.find("input[name=nummber]").val());
        if (num > 1) {
            num--;
        }
        outerContainter.find("input[name=nummber]").val(num);
        var pri = outerContainter.find(".price").text();
        if (pri % 1299 == 0) {
            outerContainter.find(".price").text(1299 * num)
        } else if (pri % 1799 == 0) {
            outerContainter.find(".price").text(1799 * num)
        } else {
            outerContainter.find(".price").text(199 * num)
        }
        console.log(datas);
        for (let i = 0; i < datas.length; i++) {
            console.log(datas[i]);

            if (datas[i].pid == str) {
                if (datas[i].nummber > 1) {
                    datas[i].nummber -= 1;
                }
            }
            p += datas[i].price * datas[i].nummber;
        }
        console.log(p);
        $('.sub_prices').text(p)
        sessionStorage.setItem("coffee", JSON.stringify(datas));
    });

    //删除所选
    $(document).on("click", ".del", function () {
        var str = '',p = 0;
        str = $(this).parents(".car_pro").find('#pid_j').val();
        for (let i = 0; i < datas.length; i++) {
            if (String(datas[i].pid) == str) {
                datas.splice(i, 1)
                $(this).parents(".car_pro").remove();
                break;
            }
        }
        for (let i = 0; i < datas.length; i++) {
            p = p + datas[i].price * datas[i].nummber;
        }
        $('.sub_prices').text(p);
        sessionStorage.setItem("coffee", JSON.stringify(datas));
    })

    //数量加减/coffee
    $('.red').on('click', function () {
        $('#nummber').val($('#nummber').val() - 1)
        if ($('#nummber').val() == 0) {
            $('#nummber').val(1)
        }
        // if ($('.pro').hasClass('con')) {
        //     $('#price').text(price_two * Number($('#nummber').val()));
        // } else {
        //     $('#price').text(price_one * Number($('#nummber').val()));
        // }
    })
    $('.add').on('click', function () {
        var adds = $('#nummber').val();
        adds++;
        $('#nummber').val(0);
        $('#nummber').val(adds);
        // if ($('.pro').hasClass('con')) {
        //     $('#price').text(price_two * Number($('#nummber').val()));
        // } else {
        //     $('#price').text(price_one * Number($('#nummber').val()));
        // }
    })

    //数量加减/ink
    $('.ink_red').on('click', function () {
        $('#nummber').val($('#nummber').val() - 1)
        if ($('#nummber').val() == 0) {
            $('#nummber').val(1)
        }
        // $('#price').text(price_ink * Number($('#nummber').val()));
    })
    $('.ink_add').on('click', function () {
        var adds = $('#nummber').val();
        adds++;
        $('#nummber').val(0);
        $('#nummber').val(adds);
        // $('#price').text(price_ink * Number($('#nummber').val()));
    })

    //设置第一张图片显示，其余隐藏
    var urls = window.location.pathname;
    if (urls.indexOf('coffee-printer-cartridge') >= 0) {
        $('.item').eq(3).show().siblings('.item').hide();
    } else {
        $('.item').eq(0).show().siblings('.item').hide();
    }

    //当鼠标经过下面的数字时，触发两个事件（鼠标悬停和鼠标离开）
    $('.tab').hover(function () {
        //获取当前i的值，并显示，同时还要清除定时器
        i = $(this).index();
        Show();
        // clearInterval(timer);
    }, function () {
        // showTime();
    });
    //页面刷新加载页面
    function load() {
        var duc = 'coffee',
            p = 0;
        for (let i = 0; i < datas.length; i++) {
            if (datas[i].pid > 4) {
                duc = 'ink';
                str_html = '<div class="car_pro"><div class="img"><img src="/imgs/' + duc + '/' + datas[i].pid + '.jpg" alt=""><input type="hidden" name="pid" value="' + datas[i].pid + '" id="pid_j"></div><div class="said"><h4>Desserts Decoration Maker</h4><span class="anno">' + datas[i].annotate + '</span> </div><div class="num"><span class="num_red" >-</span><input type="text" name="nummber" value="' + datas[i].nummber + '" id="nummbers"><span class="num_add" >+</span></div><div class="prices"><span>$</span><div class="price">' + datas[i].price * datas[i].nummber + '</div></div><div class="del"><img src="/imgs/coffee/del.png" alt=""></div></div>';
                $('#carpro').append(str_html);
            } else {
                duc = 'coffee';
                str_html = '<div class="car_pro"><div class="img"><img src="/imgs/' + duc + '/' + datas[i].pid + '.jpg" alt=""><input type="hidden" name="pid" value="' + datas[i].pid + '" id="pid_j"></div><div class="said"><h4>Desserts Decoration Maker</h4><span class="anno">' + datas[i].color + ' ' + datas[i].model + '</span> </div><div class="num"><span class="num_red" >-</span><input type="text" name="nummber" value="' + datas[i].nummber + '" id="nummbers"><span class="num_add" >+</span></div><div class="prices"><span>$</span><div class="price">' + datas[i].price * datas[i].nummber + '</div></div><div class="del"><img src="/imgs/coffee/del.png" alt=""></div></div>';
                $('#carpro').append(str_html);
            }

        }
        for (let i = 0; i < datas.length; i++) {
            p += datas[i].price * datas[i].nummber;
        }
        $('.sub_prices').text(p);
    }
    // //创建一个showTime函数
    // function showTime() {
    //     //定时器
    //     timer = setInterval(function () {
    //         //调用一个Show()函数
    //         Show();
    //         i++;
    //         //当图片是最后一张的后面时，设置图片为第一张
    //         if (i == 5) {
    //             i = 0;
    //         }
    //     }, 4000);
    // }

    //创建一个Show函数
    function Show() {
        //在这里可以用其他jquery的动画
        $('.item').eq(i).fadeIn(300).siblings('.item').fadeOut(300);
        //给.tab创建一个新的Class为其添加一个新的样式，并且要在css代码中设置该样式
        $('.tab').eq(i).addClass('active').siblings('.tab').removeClass('active');

    }

    //小球
    $(".addcar").click(function (event) {
        var addcar = $(this);
        var img = addcar.parent().find('img').attr('src');
        var flyer = $('<img class="u-flyer" src="' + img + '">');
        flyer.fly({
            start: {
                left: event.pageX, //开始位置（必填）#fly元素会被设置成position: fixed 
                top: event.pageY-$(document).scrollTop()-50 //开始位置（必填） 
            },
            end: {
                left: $("#cart").offset().left, //结束位置（必填） 
                top: $("#cart").offset().top-$(document).scrollTop(), //结束位置（必填） 
                width: 0, //结束时宽度 
                height: 0 //结束时高度 
            },
            onEnd: function () { //结束回调 
                // addcar.css("cursor", "default").removeClass('orange').unbind('click');
                // this.destory(); //移除dom 
            }
        });
    });
});